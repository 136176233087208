<template>
  <div
    class="site-header__header-one-wrap three clearfix"
    style="position: fixed; box-shadow: grey 0 0 15px"
  >
    <header class="main-nav__header-one">
      <div class="container">
        <nav class="header-navigation one stricky">
          <div class="container-box clearfix">
            <!-- Brand and toggle get grouped for better mobile display -->
            <div class="main-nav__left main-nav__left_one float-left">
              <div class="logo_one">
                <a href="index.html" class="main-nav__logo">
                  <img src="assets/images/logo.jpeg" class="main-logo" />
                </a>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
  </div>
</template>
