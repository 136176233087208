import axios from 'axios'
import { useCookies } from 'vue3-cookies'

const { cookies } = useCookies()

const apiBase = 'https://maestroapi-staging.herokuapp.com/site/'
// const apiBase = 'http://localhost:5000/site/'

const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYzMjRkNDQ5MWI5NmY4NjczYTE4ODU2NiIsIm5hbWUiOiJNZXUgQ29sw6lnaW8iLCJpYXQiOjE2NjMzNTgzNjIsImV4cCI6MTk3ODcxODM2Mn0.OGjbXtxEhg8eUSDZBwNL1jZ97uJJgC6IT8Z6jZ4xEdo'
const axiosOptions = {
  headers: {
    authorization: token,
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS'
  }
}

const getList = async (collection, fields, condition, pagination) => {
  let queryString = fields ? '?fields=' + fields : ''
  if (condition) queryString = queryString + '&cnd=' + encodeURI(condition)
  if (pagination) queryString = queryString + '&page=' + pagination.page + '&limit=' + pagination.limit
  const url = `${apiBase}${collection + queryString}`

  

  const response = await axios(url, axiosOptions)
  return response.data
}

const getSectionContent = async (sectionId, size, page) => {
  const pagination = size > 0 && page >= 0 ? `?page=${page}&limit=${size}` : ''
  const url = `${apiBase}sections/${sectionId}/contents${pagination}`

  const response = await axios(url, axiosOptions)
  return response.data
}

const getSectionSubmenu = async (section) => {
  const condition = `{ "parent": "${section._id}" }`
  const url = `${apiBase}sections/?cnd=${encodeURI(condition)}`

  const response = await axios(url, axiosOptions)
  return response.data
}

const getItemById = async (collection, itemId) => {
  const url = `${apiBase}${collection}/${itemId}?d=${Date.now()}`
  try {
    const response = await axios(url, axiosOptions)
    return response.data
  } catch (error) {
    return error
  }
}

const getItemBySlug = async (collection, slug) => {
  const url = `${apiBase}${collection}/findBySlug/${slug}`
  try {
    const response = await axios(url, axiosOptions)
    return response.data
  } catch (error) {
    // console.log(error)
    return error
  }
}

const search = async (collection, term) => {
  const url = `${apiBase}${collection}/search/${term}?fields=slug,createdAt,title,summary,fileTeaser,fileTeaser,sections`
  try {
    const response = await axios(url, axiosOptions)
    return response.data
  } catch (error) {
    // console.log(error)
    return error
  }
}

const searchOS = async (id) => {
  const url = `${apiBase}service-orders/${id}`
  try {
    const response = await axios(url, axiosOptions)
    return response.data
  } catch (error) {
    // console.log(error)
    return error
  }
}

const addStyle = (styleString) => {
  const style = document.createElement('style')
  style.textContent = styleString
  document.head.append(style)
}

const register = async (userData) => {
  const url = `${apiBase}contacts`

  try {
    const response = await axios.post(url, userData, axiosOptions)
    return response
  } catch (error) {
    return error
  }
}

const sendNotes = async (data) => {
  if (!data) data = {}
  data.navigator = navigator.userAgent
  data.datetime = new Date(Date.now())
  data = JSON.stringify(data)

  console.log(data)

  const mailOptions = {
    messageConfig: {
      from: 'noreply@roxie.com.br',
      to: 'daniloferna@gmail.com',
      html: data,
      subject: 'Log de erro'
    }
  }

  axios
    .post('https://maestro-api.roxie.com.br/mstr/mailer', mailOptions, axiosOptions)
    .then((response) => {
      console.log('response', response)
    })
    .catch((error) => {
      console.log(error)
    })
}

const submitVote = async (formId, data) => {
  const url = `${apiBase}forms/${formId}`

  try {
    const response = await axios.post(url, data, axiosOptions)

    if (response.status === 200 && response.data.status === 'success') {
      cookies.set('mcACsm', JSON.stringify(response.data))
    }

    return response.data
  } catch (error) {
    return error
  }
}

export {
  submitVote,
  getList,
  getItemById,
  getItemBySlug,
  getSectionContent,
  search,
  getSectionSubmenu,
  searchOS,
  addStyle,
  register,
  sendNotes
}
