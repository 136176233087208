<template>
  <section class="main-slider-three">
    <div class="swiper swiper-container" id="mainSwiper">
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <div
            class="image-layer"
            style="
              background-image: url(assets/images/bandeira.jpg);
              background-size: cover;
              background-position: center center;
            "
          ></div>
          <div class="container">
            <div class="swiper-slide-inner">
              <div class="row">
                <div class="col-xl-12">
                  <div class="swiper-slide-inner-text">
                    <h2>FETECC 2022</h2>
                    <a href="#projetos" class="thm-btn">
                      Vamos Votar?
                      <i class="fa fa-angle-right"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="swiper-pagination" id="main-slider-pagination"></div>
    </div>
  </section>
</template>

<script setup>
import { getItemById, addStyle } from '@/services/content'
import { reactive } from '@vue/reactivity'

const state = reactive({
  content: null
})

getItemById('contents', '6289314fda08ef274719ec85').then((response) => {
  console.log(response)
  // 
  // state.content = response.body
})
</script>
