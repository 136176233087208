<template>
  <!--Projects Start-->
  <section id="projetos" class="causes_three">
    <div class="container">
      <div class="block-title text-center" style="margin-bottom: 20px">
        <h2
          style="
            margin-bottom: 20px;
            padding-bottom: 0;
            font-weight: 100;
            font-family: Roboto;
            margin-top: -20px;
          "
        >
          Projetos 2022
        </h2>
        <p class="block-title-text" style="margin-bottom: 40px">
          Em qual projeto gostaria de votar?
        </p>
      </div>
      <section v-if="state.loaded" class="projects-slider-three">
        <div
          v-for="item in state.content"
          :key="item._id"
          class="project-item"
          :class="{
            active: state.checked?._id === item._id
          }"
        >
          <div class="causes_three_content" @click="check(item)">
            <div class="causes_three_title">
              <h5>{{ item.summary }}</h5>
              <h3>
                {{ item.title }}
              </h3>
            </div>
          </div>

          <button @click="scrollToElement()" class="thm-btn vote">VOTAR</button>
        </div>
      </section>
    </div>
  </section>

  <div id="voterForm"></div>

  <div
    v-if="state.checked?.title && !cookies?.get('mcACsm')?.newVote?.vote"
    class="message_and_volunteer_right"
    style="margin-top: 150px; margin-bottom: 80px"
  >
    <div class="message_and_volunteer_title">
      <p>Votar no projeto </p>
      <h3 style="font-size: 26px">
        {{ state.checked.title }}<br>{{ state.checked.summary }}
      </h3>
    </div>
    <div class="message_and_volunteer_input_box">
      <input v-model="state.name" type="text" placeholder="Nome*">
    </div>
    <div class="message_and_volunteer_input_box">
      <input v-model="state.email" type="email" placeholder="Email*">
    </div>
    <div class="message_and_volunteer_input_box">
      <input v-model="state.cellPhone" type="tel" placeholder="Celular*">
    </div>
    <div class="message_and_volunteer_btn">
      <button @click="vote" class="thm-btn">
        Votar<i class="fa fa-angle-right"></i>
      </button>
    </div>
  </div>
  <!--Causes Three End-->
</template>

<script setup>
import { getList, submitVote, addStyle } from '@/services/content'
import { reactive, toRefs } from '@vue/reactivity'
import { useCookies } from 'vue3-cookies'

const { cookies } = useCookies()

const state = reactive({
  content: null,
  checked: null,
  loaded: false,
  name: null,
  email: null, 
  cellPhone: null
})

getList('contents').then((response) => {
  console.log('contents!!!')

  state.content = response.data.records
  if (response.styles) addStyle(response.styles)
  state.loaded = true
})

const check = function (item) {
  state.checked = state.checked?._id === item._id ? null : item
}

const vote = async function () {
  if (cookies?.get('mcACsm')?.newVote?.vote) {
    alert('Você já votou!')
    return false
  }

  const { name, email, cellPhone, comments } = state
  if (!name || !email || !cellPhone)
    return alert('Por favor, preencha seu nome, email e celular para submeter o voto.')

  const data = {
    respondent: {
      name,
      email,
      cellPhone
    },
    vote: state.checked.slug
  }

  const response = await submitVote('6325250b394d0663ba139487', data)
  if (response) alert('Obrigado pelo seu voto!')
}

function scrollToElement() {
  let resp = cookies.get('mcACsm')
  if (resp && resp.status === 'success') {
    alert('Você já votou.')
    return
  }

  const voterForm = document.getElementById('voterForm')
  voterForm.scrollIntoView({ behavior: 'smooth' })
}
</script>
<style lang="scss">
.message_and_volunteer_input_box input,
.message_and_volunteer_input_box input[type="text"],
.message_and_volunteer_input_box input[type="tel"],
.message_and_volunteer_input_box input[type="email"] {
  height: 54px;
  width: 100%;
  border: 0;
  border-radius: 8px;
  color: #5e5858;
  font-size: 18px;
  margin-bottom: 20px;
  padding: 0 20px;
  outline: none;
}

.project-item {
  text-align: center;
}
.causes_three_content {
  padding: 0;
}

.causes_three_title {
  background: transparent;
  padding: 30px;
  display: block;
  width: 100%;
  text-align: left;
}

.active {
  .causes_three_title {
    background: green;
    padding: 30px;
    display: block;
    border-radius: 12px;
  }

  * {
    color: white;
  }

  h5 {
    color: yellow !important;
  }

  .thm-btn.vote {
    display: inline;
  }
}

.thm-btn.vote {
  display: none;
  padding: 20px 50px;
  margin: 0 0 40px 0;
  padding-right: 50px !important;
}
</style>
